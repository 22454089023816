import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage, Link } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'
import { graphql, navigate } from 'gatsby'

import { userLocation } from '_services/location'
import { sendContactForm } from '_services/contact'
import { NavbarOnly } from '_templates'
import { BlueText, Button, Container, Heading, Input, Select, Text, CountdownTimer } from '_atoms'
import { Hero, CookieConsentBar } from '_molecules'
import { SEO } from '_organisms'
// footer
import FacebookIcon from '_images/svgs/facebook.inline.svg'
import LinkedinIcon from '_images/svgs/linkedin.inline.svg'
import TwitterIcon from '_images/svgs/twitter.inline.svg'
import InstagramIcon from '_images/svgs/instagram.inline.svg'
import LaptopMessage from '_images/svgs/laptop-message.svg'
import { scrollTo } from '_utils/scroll'

import styles from './styles.module.css'

const SCHEDULE_HUBSPOT = 'https://meetings.hubspot.com/jeremy16'
const SECONDS_TO_REDIRECT_HUBSPOT = 5

const ContactPage = ({ data }) => {
  const intl = useIntl()
  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [interest, setInterest] = useState({ value: '' })
  const [projectBudget, setProjectBudget] = useState({ value: '' })
  const [location, setLocation] = useState(null)
  const [ipAddress, setIpAddress] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formError, setFormError] = useState(false)
  const { contactTitle, contactDescription, contactThumbnail } = data.contentfulPageMetatags

  const getIntlText = id => intl.formatMessage({ id })

  const FORM_FIELDS = [
    { name: 'firstname', placeholder: getIntlText('contact.fields.firstName'), required: true },
    { name: 'lastname', placeholder: getIntlText('contact.fields.lastName'), required: true },
    {
      name: 'email',
      placeholder: getIntlText('contact.fields.email'),
      required: true,
      type: 'email',
    },
    { name: 'company', placeholder: getIntlText('contact.fields.company') },
    { name: 'phone', placeholder: getIntlText('contact.fields.phone') },
  ]

  const FORM_OPTIONS = [
    {
      value: '',
      label: getIntlText('contact.interests.title'),
    },
    {
      value: 'product-definition',
      label: getIntlText('contact.interests.product'),
      project: true,
    },
    {
      value: 'dev-ux',
      label: getIntlText('contact.interests.design'),
      project: true,
    },
    {
      value: 'dev-mobile',
      label: getIntlText('contact.interests.mobile'),
      project: true,
    },
    {
      value: 'dev-web',
      label: getIntlText('contact.interests.web'),
      project: true,
    },
    {
      value: 'next-gen',
      label: getIntlText('contact.interests.nextGen'),
      project: true,
    },
    {
      value: 'work',
      label: getIntlText('contact.interests.work'),
    },
    {
      value: 'other',
      label: getIntlText('contact.interests.other'),
    },
  ]

  const FORM_PROJECT_BUDGET = [
    { value: '', label: getIntlText('contact.fields.projectBudget') },
    { value: getIntlText('contact.budget.first'), label: getIntlText('contact.budget.first') },
    { value: getIntlText('contact.budget.second'), label: getIntlText('contact.budget.second') },
    { value: getIntlText('contact.budget.third'), label: getIntlText('contact.budget.third') },
    { value: getIntlText('contact.budget.fourth'), label: getIntlText('contact.budget.fourth') },
  ]

  const setFormValue = field => ({ target }) => {
    const { value } = target
    if (!formValues[field] && formErrors[field]) setFormErrors({ ...formErrors, [field]: false })

    setFormValues({ ...formValues, [field]: value })
  }

  const handleRequiredSelect = ({ target }, name, formOptions) => {
    const { value } = target
    const selected = formOptions.find(({ value: currentValue }) => currentValue === value)

    if (value && formErrors[name]) setFormErrors({ ...formErrors, [name]: false })

    if (name === 'interest') setInterest(selected)
    if (name === 'expected_budget') setProjectBudget(selected)
  }

  const handleSubmit = event => {
    event.preventDefault()

    const errors = {}

    const formData = {
      interest: interest.label,
      expected_budget: projectBudget.label,
      location,
    }

    if (interest.project) formData.expected_budget = projectBudget.label

    FORM_FIELDS.forEach(({ name, required }) => {
      formData[name] = formValues[name]
      if (required && !formValues[name]) errors[name] = true
    })

    if (!interest.value) errors.interest = true
    if (interest.project && !projectBudget.value) errors.expected_budget = true

    setFormErrors(errors)

    if (Object.keys(errors).length > 0) return

    if (interest.project) {
      formData.expected_budget = projectBudget.value
      formData.project_description = formValues.project_description || ''
    } else {
      formData.expected_budget = ''
    }

    if (!formData.phone) {
      formData.phone = ''
    }

    if (!formData.company) {
      formData.company = ''
    }

    if (!formData.location) {
      formData.location = ''
    }

    if (interest.value === 'work' || interest.value === 'other')
      formData.message = formValues.message || ''

    sendContactForm(formData, ipAddress)
      .then(() => {
        setTimeout(() => {
          navigate(SCHEDULE_HUBSPOT)
        }, SECONDS_TO_REDIRECT_HUBSPOT * 1000 - 400)
        setFormSubmitted(true)
      })
      .catch(() => {
        setFormError(true)
        return setFormSubmitted(false)
      })
      .then(() => {
        // Scroll to the top of the contactForm element on submission
        scrollTo('contactForm')
      })
  }

  useEffect(() => {
    userLocation().then(locationData => {
      setLocation(locationData.locationString)
      setIpAddress(locationData.ipAddress)
    })
  }, [])

  const renderInputs = () => (
    <form id="contactFormId" onSubmit={handleSubmit} className={styles.formContent}>
      {FORM_FIELDS.map(({ name, ...field }) => (
        <div key={name} className={styles.formField}>
          <Input
            id={name}
            label={field.placeholder}
            {...field}
            type={field.type || 'text'}
            key={name}
            name={name}
            className={styles.formInput}
            value={formValues[name] || ''}
            error={formErrors[name]}
            onChange={setFormValue(name)}
          />
        </div>
      ))}

      <div className={styles.formField}>
        <Select
          id="Interest"
          name="Interest"
          label={getIntlText('contact.interests.title')}
          value={interest.value}
          placeholder={getIntlText('contact.interests.title')}
          options={FORM_OPTIONS}
          onChange={e => handleRequiredSelect(e, 'interest', FORM_OPTIONS)}
          error={formErrors.interest}
          required
        />
      </div>

      {interest.project && (
        <>
          <div className={styles.formField}>
            <Select
              id="Budget"
              name="Budget"
              label={getIntlText('contact.fields.projectBudget')}
              className={styles.formInput}
              value={projectBudget.value}
              placeholder={getIntlText('contact.fields.projectBudget')}
              options={FORM_PROJECT_BUDGET}
              onChange={e => handleRequiredSelect(e, 'expected_budget', FORM_PROJECT_BUDGET)}
              error={formErrors.expected_budget}
              required
            />
          </div>
          <div className={styles.formField}>
            <Input
              id="Project-Description"
              name="Project-Description"
              label={getIntlText('contact.fields.projectAbout')}
              textarea
              required
              value={formValues.project_description || ''}
              placeholder={getIntlText('contact.fields.projectAbout')}
              className={styles.formInput}
              onChange={setFormValue('project_description')}
            />
          </div>
        </>
      )}

      {interest.value === 'work' && (
        <Text size="18" className={styles.formNote}>
          <FormattedMessage id="contact.work.text" />{' '}
          <Link className={styles.formLink} to="/careers">
            <FormattedMessage id="contact.work.page" />
          </Link>{' '}
          <FormattedMessage id="contact.work.and" />{' '}
          <Link className={styles.formLink} to="/careers/#positions">
            <FormattedMessage id="contact.work.positions" />
          </Link>
        </Text>
      )}

      {interest.value && !interest.project && (
        <div className={styles.formField}>
          <Input
            id="message"
            label={getIntlText('contact.fields.message')}
            textarea
            required
            name="message"
            value={formValues.message || ''}
            placeholder={getIntlText('contact.fields.message')}
            className={styles.formInput}
            onChange={setFormValue('message')}
          />
        </div>
      )}
      <Button id="contactSubmitId" type="primary" htmlType="submit" className={styles.formSubmit}>
        <FormattedMessage id="contact.submit" />
      </Button>
    </form>
  )

  const showForm = () => {
    setFormError(false)
    setFormSubmitted(false)
  }

  const renderFormSubmitted = () => (
    <Fade distance="25%" top>
      <div className={styles.submitted}>
        <Text size="32" color="dark" bold className={styles.submittedTitle}>
          <FormattedMessage id="contact.submitted.title" />
        </Text>

        <Text size="32" color="dark" className={styles.submittedSubtitle}>
          <FormattedMessage id="contact.submitted.subtitle" />
          <span className={styles.squared}>!</span>
        </Text>

        <LaptopMessage className={styles.submittedImage} />

        <Text size="24" color="dark" className={styles.submittedDescription}>
          <FormattedMessage id="contact.submitted.description" />
        </Text>

        <CountdownTimer
          seconds={SECONDS_TO_REDIRECT_HUBSPOT}
          size={120}
          strokeBgColor="white"
          strokeColor="#0058FF"
          strokeWidth={10}
        />
      </div>
    </Fade>
  )

  const renderFormError = () => (
    <Fade distance="25%" top>
      <div className={styles.submitted}>
        <Text size="32" color="dark" bold className={styles.submittedTitle}>
          <FormattedMessage id="contact.error.title" />
        </Text>

        <Text size="24" color="dark" className={styles.submittedDescription}>
          <FormattedMessage id="contact.error.description" />
        </Text>

        <Button type="primary" onClick={showForm}>
          <FormattedMessage id="contact.error.button" />
        </Button>
      </div>
    </Fade>
  )

  return (
    <NavbarOnly>
      <SEO
        title={contactTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={contactDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={contactThumbnail.file.url}
      />

      <div className={styles.page}>
        <Hero
          title="contact.hero.title"
          subTitle="contact.hero.subtitle"
          className={styles.heroMobile}
        />

        <Container>
          <Row className={styles.pageRow}>
            <Col xs={12} sm={12} md={6} lg={6} className={styles.heroDesktop}>
              <div className={styles.heroContent}>
                <Fade distance="25%" top>
                  <Heading type="h1" color="white" className={styles.heroTitle} bold>
                    <FormattedMessage id="contact.hero.title" />
                    <BlueText light squared>
                      .
                    </BlueText>
                  </Heading>
                </Fade>
                <Fade distance="25%" bottom>
                  <Text size="24" color="white">
                    <FormattedMessage id="contact.hero.subtitle" />
                  </Text>
                </Fade>
              </div>
            </Col>

            <Col xs={12} sm={12} md={6} lg={6} className={styles.form} id="contactForm">
              {formError && renderFormError()}
              {formSubmitted && !formError && renderFormSubmitted()}
              {!formSubmitted && !formError && renderInputs()}
            </Col>
          </Row>
        </Container>
        <CookieConsentBar />
      </div>

      {!data.hideFooter && (
        <footer className={styles.footer}>
          <Container>
            <Row className={styles.footerRow}>
              <Col xs={12} sm={12} md={5} lg={5} className={styles.footerLinks}>
                <div className={styles.footerNetworks}>
                  <a
                    href="https://www.facebook.com/cheesecakelabs"
                    className={styles.footerNetworksLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/cheesecake-labs/"
                    className={styles.footerNetworksLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <LinkedinIcon />
                  </a>
                  <a
                    href="https://twitter.com/cheesecakelabs"
                    className={styles.footerNetworksLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    href="https://www.instagram.com/cheesecakelabs/"
                    className={styles.footerNetworksLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              </Col>
              <Col xs={12} sm={12} md={7} lg={7}>
                <Text size="24" color="white" className={styles.footerTitle}>
                  <FormattedMessage id="contact.footer" />
                  <span className={styles.squared}>?</span>
                </Text>

                <Text size="24" color="white" bold className={styles.footerVirtualAddresses}>
                  (415) 766 8860
                  <br />
                  <a href="mailto:info@cheesecakelabs.com">info@cheesecakelabs.com</a>
                </Text>

                <Row className={styles.footerAddresses}>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Text size="20" color="primary" bold>
                      <FormattedMessage id="footer.sanFrancisco" />
                    </Text>
                    <Text size="16" color="white" className={styles.footerAddressesNumber}>
                      415 766 8860
                    </Text>
                    <Text size="14" color="white" className={styles.footerAddressesStreet}>
                      237 Kearny Street #9055
                    </Text>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6} className={styles.footerAddressesItem}>
                    <Text size="20" color="primary" bold>
                      <FormattedMessage id="footer.florianopolis" />
                    </Text>
                    <Text size="16" color="white" className={styles.footerAddressesNumber}>
                      +55 (48) 3206-5246
                    </Text>
                    <Text size="14" color="white" className={styles.footerAddressesStreet}>
                      Av. Des. Vitor Lima 260 - 10th floor
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </footer>
      )}
    </NavbarOnly>
  )
}

export const query = graphql`
  query ContactPageContent($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      contactDescription
      contactTitle
      contactThumbnail {
        file {
          url
        }
      }
    }
  }
`

ContactPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default ContactPage
